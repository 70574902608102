import { ChevronDown, Check } from 'react-feather';
import { useState } from 'react';

const Dropdown = ({ title = 'Sortera efter', onClick = null, items = [], selected = '' }) => {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <button
        className="block h-12 w-auto px-2 overflow-hidden border-2"
        onClick={() => setOpen(!open)}
      >
        <p className="text-left mt-1">
          <ChevronDown size="32" className="float-left p-1 -mt-1" />
          <span className="p-1">{title}</span>
        </p>
      </button>
      <div
        className={`p-2 w-auto bg-white border-2 shadow-xl absolute z-10 ${open ? '' : 'hidden'}`}
      >
        {items.map((i, ii) => (
          <a
            key={ii}
            href="#"
            className="block px-4 py-2 text-gray-800 hover:bg-gray-500 hover:text-white"
            onClick={() => {
              setOpen(!open);

              if (typeof onClick === 'function') {
                onClick(i.value);
              }
            }}
          >
            {i.title}

            <Check
              size="32"
              className={`float-right p-1 -mt-1 ${i.value == selected ? '' : 'hidden'}`}
            />
          </a>
        ))}
      </div>
    </div>
  );
};

export default Dropdown;
