import { baseUrl } from '../../lib/url';

const ImageGrid = ({ images = [], noPadding = false, className = 'p-8 mb-10' }) => (
  <div className={`container mx-auto ${className}`}>
    <div className="flex flex-row flex-wrap -mx-2">
      {images.map((image, ii) => (
        <div
          key={ii}
          className={`w-full ${
            images.length === 1 ? '' : 'sm:w-1/3'
          } h-32 md:h-48 sm:mb-0 px-2 mt-4`}
        >
          <a
            className="block w-full h-full bg-grey-dark bg-no-repeat bg-center bg-cover rounded-md"
            href={image.url ? baseUrl(image.url) : '#'}
            title={image.title}
            style={{
              backgroundImage: `url(${image.image})`,
            }}
          >
            <div className="flex flex-wrap justify-center content-center">
              <span className="bg-white h-12 uppercase text-black text-center font-bold text-lg lg:text-md p-2 mt-10 lg:mt-16 rounded-md">
                {image.title}
              </span>
            </div>
          </a>
        </div>
      ))}
    </div>
  </div>
);

export default ImageGrid;
