import React from 'react';
import Head from 'next/head';
import ReactPaginate from 'react-paginate';
import Router from 'next/router';
import Products from '../../../components/products';
import Hero from '../../../components/hero';
import Link from '../../../components/link';
import Dropdown from '../../../components/dropdown';
import Loading from '../../../components/loading';
import LinkList from '../../../components/linkList';
import { findProducts, presenter } from '../../../data';
import { baseUrl } from '../../../lib/url';

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const perPage = 9;

const createUrl = (url, page, query, sortBy) => {
  if (!url) {
    return baseUrl(url);
  }

  url = url.replace(/\/sida\/\d+\//, '');
  if (!query && url.indexOf('sida') === -1) {
    url += (url[url.length - 1] === '/' ? '' : '/') + (page !== '/' ? 'sida/' + page + '/' : '');
  }

  const obj = {
    query,
    sort: sortBy,
  };

  if (query) {
    obj.page = page;
  }

  let str = '';
  for (const key in obj) {
    if (str != '') {
      str += '&';
    }

    if (obj[key]) {
      str += key + '=' + encodeURIComponent(obj[key]);
    }
  }

  if (str) {
    str = '?' + str.replace('&&', '&');
  }

  return baseUrl(url + str);
};

const paginate = (array, page_size, page_number) => {
  // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
  return array.slice((page_number - 1) * page_size, page_number * page_size);
};

const cleanId = (id) => {
  switch (id) {
    case 'alla-presenttips':
      return 'alla';
  }
  return id.replace(/presenter\-/, '');
};

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.onDropdownClick = this.onDropdownClick.bind(this);

    this.state = {
      sortBy: '',
    };
  }

  static async getInitialProps({ query }) {
    const categories = query.categories ? query.categories : [cleanId(query.id)];
    const products = findProducts(categories);

    let pagesLen = parseInt(products.length / perPage);
    if (products.length / perPage > pagesLen) {
      pagesLen += 1;
    }

    return {
      content: query,
      products: products,
      total: products.length,
      pages: pagesLen,
    };
  }

  componentDidMount() {
    const { sortBy } = this.getQueryStrings();

    if (sortBy) {
      this.setState({
        sortBy: sortBy,
      });
    }
  }

  getQueryStrings() {
    const result = {
      sortBy: '',
      page: 1,
      query: '',
    };

    if (typeof window === 'undefined') {
      return result;
    }

    const qs = decodeURIComponent(window.location.search.toLowerCase());

    let page = /page=(.*?)(?:&|$)/.exec(qs) || '1';
    page = page instanceof Array ? page[1] : '1';
    result.page = parseInt(page);

    const sortBy = /sort=(.*?)(?:&|$)/.exec(qs) || '';
    result.sortBy = sortBy instanceof Array ? sortBy[1] : '';

    const query = /query=(.*?)(?:&|$)/.exec(qs) || '';
    result.query = query instanceof Array ? query[1] : '';

    return result;
  }

  onDropdownClick(value) {
    const { query } = this.props;

    window.location = createUrl(window.location.pathname, 1, query, value);
  }

  render() {
    const { sortBy } = this.state;
    let { content, page, pages, products, total, query, isSearch } = this.props;

    page = page || 1;

    if (!content) {
      return <Loading />;
    }

    content = Object.assign(
      {
        meta_title: '',
        title: '',
        description: '',
        subtitle: '',
        bottom_title: '',
        bottom_text: [],
      },
      content,
    );

    if (!content.meta_title) {
      const date = content.title.indexOf('(') !== -1;
      let title = content.title.toLowerCase().split('(').shift().trim();

      // (14 feb)
      if (date) {
        title = `presenter till ${title}`;
      }

      // present => presenter
      if (title.indexOf('presenter') === -1) {
        title = title.replace('present', 'presenter');
      }

      // special
      title = title.replace('flickvän', 'din flickvän');
      title = title.replace('pojkvän', 'din pojkvän');

      content.meta_title = `${capitalizeFirstLetter(title)} | Alla Presenter`;
    } else {
      content.meta_title = `${capitalizeFirstLetter(content.meta_title).replace(
        '{year}',
        new Date().getFullYear(),
      )}| Alla Presenter`;
    }

    if (!content.description) {
      content.description = content.subtitle;
    }

    const keys = ['sortBy', 'total', 'query'];
    for (const key of keys) {
      content.description = content.description.replace('[' + key + ']', this.state[key]);
      content.subtitle = content.subtitle.replace('[' + key + ']', this.state[key]);
    }

    if (sortBy) {
      switch (sortBy) {
        case 'stigande':
          products = products.sort((a, b) => {
            return parseInt(a.price) - parseInt(b.price);
          });
          break;
        case 'fallande':
          products = products.sort((a, b) => {
            return parseInt(b.price) - parseInt(a.price);
          });
          break;
        case 'alfabetisk':
          products = products.sort((a, b) => {
            if (a.title < b.title) return -1;
            if (a.title > b.title) return 1;
            return 0;
          });
          break;
      }
    }

    products = paginate(products, perPage, page);

    const pagination = [];
    for (let i = 0, l = pages + 1; i < l; i++) {
      pagination.push(
        <a
          key={i}
          href={`?page=${i}`}
          className={`px-4 py-2 mx-2 text-white mb-4 ${i === page ? 'bg-gray-600' : 'bg-gray-800'}`}
        >
          {i + 1}
        </a>,
      );
    }

    const ldjson = `{
      "@context": "https://schema.org",
      "@type": "WebPage",
      "name": "${content.meta_title}",
      "description": "${content.description}",
      "url": "${baseUrl(content.url)}"
    }`;

    let html = (
      <div className="flex justify-center my-10">
        <div className="flex flex-col">
          <p className="text-gray-800">Tyvärr, inga produkter hittades.</p>
        </div>
      </div>
    );

    if (!products.length && isSearch) {
      html = (
        <div className="container mx-auto pt-3">
          <h2 className="mt-12 text-3xl tracking-tight font-extrabold text-gray-900 sm:text-xl md:text-2xl text-center">
            Vem letar du present till?
          </h2>
          <LinkList
            items={presenter.mottagare.items}
            linkColor="pink"
            images={[
              {
                title: 'Till de yngre',
                image: '/images/grid/barn.jpg',
                url: baseUrl('/presenter/presenter-till-barn/'),
              },
              {
                title: 'Till henne',
                image: '/images/grid/henne.jpg',
                url: baseUrl('/presenter/presenter-till-henne/'),
              },
              {
                title: 'Till honom',
                image: '/images/grid/honom.jpg',
                url: baseUrl('/presenter/presenter-till-honom/'),
              },
            ]}
          />
        </div>
      );
    } else if (content.title && products.length) {
      html = (
        <>
          <div className="max-w-2xl mx-auto py-6 px-4 sm:py-12 sm:px-6 lg:max-w-7xl lg:px-8">
            <div className="mt-10">
              <Dropdown
                title="Sortera efter"
                items={[
                  { title: 'Inget', value: '' },
                  { title: 'Priser stigande', value: 'stigande' },
                  { title: 'Priser fallande', value: 'fallande' },
                  { title: 'Alfabetisk', value: 'alfabetisk' },
                ]}
                onClick={this.onDropdownClick}
                selected={sortBy}
              />
            </div>
          </div>
          <Products products={products} />
          <div className="flex justify-center content-center">
            <h3 className="text-lg font-bold my-4">
              Visar {(page - 1) * perPage + 1}-
              {(page - 1) * perPage + perPage + 1 > total
                ? total
                : (page - 1) * perPage + perPage + 1}{' '}
              av {total} resultat
            </h3>
          </div>
          <div className="flex justify-center content-center">
            <ReactPaginate
              previousLabel="Föregående"
              nextLabel="Nästa"
              breakLabel="..."
              breakClassName="block hover:text-white hover:bg-gray-800 text-gray-800 px-3 py-2 border-r border-grey-light"
              pageCount={pages}
              marginPagesDisplayed={1}
              pageRangeDisplayed={3}
              forcePage={page - 1 < 0 ? 0 : page - 1}
              hrefBuilder={(page) => `${createUrl(content.url, Math.ceil(page), query, sortBy)}`}
              containerClassName="flex list-reset border border-grey-light rounded w-auto font-sans"
              previousLinkClassName="block hover:text-white hover:bg-gray-800 text-gray-800 px-3 py-2 border-r border-grey-light hidden lg:block"
              pageLinkClassName="block cursor-pointer hover:text-white hover:bg-gray-800 text-gray-800 border-r border-grey-light px-3 py-2"
              nextClassName="block hover:text-white hover:bg-gray-800 text-gray-800 border-r border-grey-light px-3 py-2 hidden lg:block"
              activeClassName="active"
              onPageChange={(data) =>
                (window.location = `${createUrl(
                  content.url,
                  Math.ceil(data.selected) + 1,
                  query,
                  sortBy,
                )}`)
              }
            />
          </div>
          {content.bottom_title && (
            <div className="flex justify-center mt-10 pb-10">
              <div className="flex flex-col">
                {content.bottom_title && (
                  <h3 className="text-lg lg:text-3xl text-center mb-2">{content.bottom_title}</h3>
                )}
                {content.bottom_text.map((p, pi) => (
                  <p className="px-2 mb-2" key={pi}>
                    {p}
                  </p>
                ))}
              </div>
            </div>
          )}
        </>
      );
    } else if (!content.title) {
      content.title = '404';
    } else if (!products.length) {
      html = (
        <div className="flex justify-center mb-20 mt-20">
          <div className="flex flex-col">
            <p>
              Just nu har vi inga produkter upplaggda för denna kategori. Vänligen titta in igen
              senare eller titta in våra andra{' '}
              <Link to="/tillfallen/" target="_self">
                tillfällen
              </Link>{' '}
              och{' '}
              <Link to="/kategorier/" target="_self">
                kategorier
              </Link>{' '}
              eller{' '}
              <Link to="/presenter/alla-presenttips/" target="_self">
                alla våra presenttips
              </Link>
              .
            </p>
          </div>
        </div>
      );
    }

    return (
      <div>
        <Head>
          <title>{content.meta_title}</title>
          <link rel="canonical" href={baseUrl(content.url)} />
          <meta name="description" content={content.description} />
          <meta property="og:locale" content="sv_SE" />
          <meta property="og:type" content="article" />
          <meta property="og:title" content={content.meta_title} />
          <meta property="og:description" content={content.description} />
          <meta property="og:url" content={baseUrl(content.url)} />
          <meta property="og:site_name" content="Alla Presenter" />
          <meta property="og:image" content={baseUrl('/images/logo.png')} />
          <meta name="twitter:title" content={content.meta_title} />
          <meta name="twitter:description" content={content.description} />
          <meta name="twitter:image" content={baseUrl('/images/logo.png')} />
          <meta name="twitter:card" content="summary" />
          <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: ldjson }} />

          {Number(page) < pages && (
            <link rel="next" href={baseUrl(createUrl(content.url, Number(page) + 1))} />
          )}
          {Number(page) > 1 && (
            <link
              rel="prev"
              href={baseUrl(
                createUrl(content.url, Number(page) - 1 === 1 ? '/' : Number(page) - 1),
              )}
            />
          )}
        </Head>

        <Hero {...content} h1 bgColor="white" />

        <div className="container mx-auto">{html}</div>
      </div>
    );
  }
}

export default Index;
