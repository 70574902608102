const Icon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' version='1' viewBox='0 0 128 128'>
    <rect width='100%' height='100%' fill='#FFF' />
    <g>
      <circle cx='16' cy='64' r='16' />
      <circle
        cx='16'
        cy='64'
        r='16'
        fill='#555'
        fillOpacity='0.67'
        transform='rotate(45 64 64)'
      />
      <circle
        cx='16'
        cy='64'
        r='16'
        fill='#949494'
        fillOpacity='0.42'
        transform='rotate(90 64 64)'
      />
      <circle
        cx='16'
        cy='64'
        r='16'
        fill='#ccc'
        fillOpacity='0.2'
        transform='rotate(135 64 64)'
      />
      <circle
        cx='16'
        cy='64'
        r='16'
        fill='#e1e1e1'
        fillOpacity='0.12'
        transform='rotate(180 64 64)'
      />
      <circle
        cx='16'
        cy='64'
        r='16'
        fill='#e1e1e1'
        fillOpacity='0.12'
        transform='rotate(225 64 64)'
      />
      <circle
        cx='16'
        cy='64'
        r='16'
        fill='#e1e1e1'
        fillOpacity='0.12'
        transform='rotate(270 64 64)'
      />
      <circle
        cx='16'
        cy='64'
        r='16'
        fill='#e1e1e1'
        fillOpacity='0.12'
        transform='rotate(315 64 64)'
      />
      <animateTransform
        attributeName='transform'
        calcMode='discrete'
        dur='720ms'
        repeatCount='indefinite'
        type='rotate'
        values='0 64 64;315 64 64;270 64 64;225 64 64;180 64 64;135 64 64;90 64 64;45 64 64'
      />
    </g>
  </svg>
)

const Loading = ({ title = 'Laddar...' }) => (
  <div className='container mx-auto'>
    <div className='flex flex-col items-start content-center justify-center w-full p-10 pt-12 pb-24 mx-auto lg:p-0 lg:w-1/2'>
      <h1 className='my-4 text-lg font-bold lg:text-xl text-shadow'>{title}</h1>
      <p className='mb-10 leading-tight leading-normal text-shadow text-center'>
        <Icon />
      </p>
    </div>
  </div>
)

export default Loading
